<template>
  <div class="policemen_warp">
    <div class="main_top">
      <div style="display: flex">
        <el-select
          v-model="procuratorateid"
          placeholder="请选择检察院名称"
          style="width: 300px"
          
        >
          <el-option
            v-for="item in proList"
            :key="item.id"
            :value="item.id"
            :label="item.procuratoratename"
            :disabled="disabled"
            
          ></el-option>
        </el-select>
        <el-input
          v-model="selectTxt"
          placeholder="请输入检察院用户信息搜索"
          style="width: 300px;margin-left:10px;margin-right:10px;"
        ></el-input>
        <el-button
          slot="reference"
          @click="departSelect"
          class="style_button style_button_Sel"
          icon="el-icon-search"
          >搜索</el-button
        >
      </div>
      <div style="display: flex">
        <div>
          <el-button class="style_button" type="text" @click="handleAdd()"
            >添加</el-button
          >
          <el-button
            class="style_button"
            style="background: white; color: #044bfe; border: 1px solid #044bfe"
            type="text"
            @click="handleExport()"
            >导出</el-button
          >
          <el-dialog
            :visible.sync="dialogFormVisible"
            @close="closeDialog"
            :close-on-click-modal="false"
          >
            <div slot="title" class="dialog_title">
              <img src="../../assets/zhcpIcon/add_icon.png" alt="" />
              <span>{{ titleName }}</span>
            </div>
            <el-form :model="form">
              <el-form-item :label-width="formLabelWidth">
                <div class="form_label" slot="label">
                  <span>*</span>用户姓名
                </div>
                <el-input v-model="form.username" autocomplete="off" :disabled="showdetail"></el-input>
              </el-form-item>
              <el-form-item :label-width="formLabelWidth">
                <div class="form_label" slot="label">
                  <span>*</span>用户性别
                </div>
                <el-select v-model="form.sex" placeholder="请选择" :disabled="showdetail">
                  <el-option
                    v-for="item in allSexy"
                    :key="item.id"
                    :value="item.id"
                    :label="item.gender" 
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label-width="formLabelWidth">
                <div class="form_label" slot="label"><span>*</span>手机号</div>
                <el-input v-model="form.phone" autocomplete="off" :disabled="showdetail"></el-input>
              </el-form-item>
              <el-form-item :label-width="formLabelWidth">
                <div class="form_label" slot="label">
                  <span>*</span>所属检察院
                </div>
                <el-select
                  v-model="form.procuratorateid"
                  placeholder="请选择检察院"
                  :disabled="showdetail"
                  @change="changepro"
                >
                  <el-option
                    v-for="item in proList1"
                    :key="item.id"
                    :value="item.id"
                    :label="item.procuratoratename"
                    
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label-width="formLabelWidth" v-if="form.typeid == 2 || form.typeid == 8 || form.typeid == 9 ">
                <div class="form_label" slot="label">
                  <span>*</span>部门名称
                </div>
                <el-select
                  v-model="form.deptid"
                  placeholder="请选择部门"
                  :disabled="showdetail"
                >
                  <el-option
                    v-for="item in depList"
                    :key="item.id"
                    :value="item.id"
                    :label="item.depname"
                    
                  ></el-option>
                </el-select>
              </el-form-item>
              <!-- <el-form-item :label-width="formLabelWidth">
                <div class="form_label" slot="label"><span>*</span>备注</div>
                <el-input v-model="form.remark" autocomplete="off" :disabled="showdetail"></el-input>
              </el-form-item> -->
              <el-form-item :label-width="formLabelWidth">
                <div class="form_label" slot="label">
                  <span>*</span>用户类型
                </div>
                <el-select v-model="form.typeid" placeholder="请选择" :disabled="showdetail">
                  <el-option
                    v-for="item in typeList"
                    :key="item.id"
                    :value="item.id"
                    :label="item.typesname"
                    
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button @click="dialogFormVisible = false">取 消</el-button>
              <el-button type="primary" @click="handleClick()">确 定</el-button>
            </div>
          </el-dialog>
        </div>
      </div>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      ref="multipleTable"
      @selection-change="handleSelectionChange"
      tooltip-effect="dark"
    >
      <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
      <el-table-column label="ID" width="50" type="index">
        <!-- <template slot-scope="scope">
          <span>{{ scope.row.id }}</span>
        </template> -->
      </el-table-column>
      <el-table-column label="检察院名称">
        <template slot-scope="scope">
          <p>{{ scope.row.procuratorate.refname }}</p>
        </template>
      </el-table-column>
      <el-table-column label="姓名">
        <template slot-scope="scope">
          <p>{{ scope.row.username }}</p>
        </template>
      </el-table-column>
      <el-table-column label="性别">
        <template slot-scope="scope">
          <p>{{ scope.row.sex == 0 ? "女" : "男" }}</p>
        </template>
      </el-table-column>
      <el-table-column label="手机号码">
        <template slot-scope="scope">
          <p>{{ scope.row.phone }}</p>
        </template>
      </el-table-column>
      <el-table-column label="用户类型" width="200">
        <template slot-scope="scope">
          <p v-if="scope.row.types.id == 1 ">院领导</p>
          <p v-if="scope.row.types.id == 2 ">检察官</p>
          <p v-if="scope.row.types.id == 3 ">听证管理员</p>
          <p v-if="scope.row.types.id == 7 ">普通用户</p>
          <p v-if="scope.row.types.id == 8 ">检察官助理</p>
          <p v-if="scope.row.types.id == 9 ">书记员</p>
        </template>
      </el-table-column>
      <el-table-column label="操作" class="operate" width="400">
        <template slot-scope="scope">
          <el-button
            size="mini"
            @click="handleDetail(scope.row.id)"
            class="editColor"
            >详情</el-button
          >
          <el-button
            size="mini"
            @click="handleEdit(scope.row.id)"
            class="editColor"
            >编辑</el-button
          >
          <el-button
            size="mini"
            @click="handleStop(scope.row.id, scope.row.state)"
            :style="{
              background: scope.row.state == 1 ? '#FF4E4E' : '#2FC25B',
            }"
            >{{ scope.row.state == 1 ? "停用" : "启动" }}</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="handleRemove(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
        hide-on-single-page
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { Message } from "element-ui";
import {
  getAllUserInfo,
  getUserInfoById,
  updateUserInfo,
  deleteUserInfo,
  addUserInfo,
} from "@/api/user";
import { getAllSpecialty, getProInfoList, getDepartmentByPage } from "@/api/system";
import { downloadFile } from "@/util/formtime";
import { BASE_URL } from "@/config";
export default {
  data() {
    return {
      options: [],
      perList: [],
      value: [],
      list: [],
      loading: false,
      states: [],
      dialogFormVisible: false,
      form: {},
      allSexy: [
        { id: 1, gender: "男" },
        { id: 0, gender: "女" },
      ],
      typeList:[{
        id:1,
        typesname:'院领导'
      },
      {
        id:2,
        typesname:'检察官'
      },
      {
        id:3,
        typesname:'听证管理员'
      },
      {
        id:8,
        typesname:'检察官助理'
      },
      {
        id:9,
        typesname:'书记员'
      }],
      formLabelWidth: "95px",
      tableData: [], //干警列表
      total: 0, //总条数
      page: 1, //页数
      pageSize: 10, //每页限制
      currentPage: 1, //当前页数
      multipleSelection: [],
      allSpecialty: [],
      proList: [],
      proList1: [],
      depList: [],
      getInfo: [],
      selectTxt: "", //搜索当前项
      titleName: "",
      procuratorateid: null,
      selecttype: 0,
      disabled: false,
      typelist:[],
      showdetail: false,
    };
  },
  mounted() {
    this.list = this.states.map((item) => {
      return { value: `value:${item}`, label: `label:${item}` };
    });
    this.perList = JSON.parse(window.localStorage.getItem("permissionidList"));
    
    this.typeid = JSON.parse(window.localStorage.getItem("tltz_userinfo")).types.id;
    if( this.typeid == 6){
      this.procuratorateid = -1;
    }else{
      this.procuratorateid = JSON.parse(window.localStorage.getItem("tltz_userinfo")).procuratorate.id;
      this.disabled = true

    }
    console.log(JSON.parse(window.localStorage.getItem("tltz_userinfo")));
    this._getAllUserInfo();
    this._getAllSpecialty();
    this.getproList();


  },
  methods: {
    changepro(e){
      console.log(e)
      this.getdepList(e);
      
    },
    getdepList(e) {
      const procuratorateid = e;
      getDepartmentByPage(1,999,procuratorateid).then((data) => {
        this.depList = data.data.list;
      });
    },
    //获取列表
    getproList() {
      getProInfoList(this.page, this.pageSize).then((data) => {
        this.proList = data.data.list;
        this.proList1 = data.data.list;
        this.proList.unshift({
          id: -1,
          procuratoratename: '全部'
        })
      });
    },
    //获取列表
    _getAllUserInfo() {
       const info = {
        page: this.page,
        pageSize: this.pageSize,
        procuratorateid: this.procuratorateid,
        selecttype: this.selecttype,
        conditions: this.selectTxt
      };
      getAllUserInfo(info).then(async (data) => {
        if (data.data == null) {
          this.page = 1;
          this.tableData = [];
          Message.error(data.msg);
          // await this._getAllUserInfo();
          return;
        } else {
          this.tableData = data.data.list;
          this.total = data.data.total;
          this.currentPage = data.data.pageNum;
        }
      });
    },
    //获取全部专业
    _getAllSpecialty() {
      getAllSpecialty().then((data) => {
        this.allSpecialty = data.data;
      });
    },
    // //添加
    handleAdd() {
      // let res = this.perList.filter((val) => val == 2);
      // console.log(res)
      // if(!res.length){
      //   Message.error('您没有这个权限');
        
      // }else{
      this.dialogFormVisible = true;
      this.form = {};
      this.currentId = "";
      this.titleName = "新增用户信息";
      // }
    },
    //修改
    handleEdit(value) {
      let res = this.perList.filter((val) => val == 5);
      console.log(res)
      if(!res.length){
        Message.error('您没有这个权限');
        
      }else{
      this.dialogFormVisible = true;
      this.currentId = value;
      this.titleName = "编辑用户信息";
      getUserInfoById(value).then((data) => {
        data.data.procuratorateid = data.data.procuratorate.id;
        data.data.typeid = data.data.types.id;
        if( data.data.typeid == 2 || data.data.typeid == 8 ||data.data.typeid == 9 ){
          data.data.deptid = data.data.department.id;
        }
        this.form = data.data;
        console.log(data.data)
        this.getdepList(data.data.procuratorateid)
      });
      this.showdetail = false;
      }
    },
    //详情
    handleDetail(value) {
      // let res = this.perList.filter((val) => val == 3);
      // console.log(res)
      // if(!res.length){
      //   Message.error('您没有这个权限');
        
      // }else{
      this.dialogFormVisible = true;
      this.currentId = value;
      this.titleName = "查看用户信息";
      getUserInfoById(value).then((data) => {
        data.data.procuratorateid = data.data.procuratorate.id;
        data.data.typeid = data.data.types.id;
        if( data.data.typeid == 2 ){
          data.data.deptid = data.data.department.id;
        }
        this.form = data.data;
        this.getdepList(data.data.procuratorateid)
      });
      this.showdetail = true;
      // }
    },
    //点击确定
    handleClick() {
      const id = this.currentId;
      this.poster();
      if (!id) {
        addUserInfo(this.form).then(async (data) => {
          if (data.code !== 200) {
            Message.error(data.msg);
            return;
          }
          this.dialogFormVisible = false;
          Message.success("提交成功！");
          await this._getAllUserInfo();
        });
      } else {
        updateUserInfo(this.form).then(async (data) => {
          if (data.code !== 200) {
            Message.error(data.msg);
            return;
          }
          this.dialogFormVisible = false;
          Message.success("提交成功！");
          await this._getAllUserInfo();
        });
      }
    },
    handleStop(d, s) {
      let res = this.perList.filter((val) => val == 4);
      console.log(res)
      if(!res.length){
        Message.error('您没有这个权限');
        
      }else{
      console.log(d, s);
      let is = s,
        id = d;
      if (is == 0) {
        is = 1;
      } else {
        is = 0;
      }
      this.$confirm("是否变更该用户当前状态？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          const params = {
            id, 
            state: is
          }
          updateUserInfo(params).then(async (data) => {
            if (data.code !== 200) return Message.error("变更状态失败！");
            await this._getAllUserInfo();
            Message.success("变更状态成功！");
          });
        })
        .catch(() => {
          Message.info("已取消!");
        });
      }
    },
    //删除
    handleRemove(id) {
      let res = this.perList.filter((val) => val == 6);
      console.log(res)
      if(!res.length){
        Message.error('您没有这个权限');
        
      }else{
      this.$confirm("是否删除用户信息？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          deleteUserInfo(id).then(async (data) => {
            if (data.code !== 200) return Message.error("删除失败");
            console.log(data.code);
            await this._getAllUserInfo();
          });
          Message.success("删除成功！");
        })
        .catch(() => {
          Message.info("已取消删除!");
        });
      }
    },
    //搜索
    departSelect() {

      this._getAllUserInfo();
      // let name = this.selectTxt;
      // this.page = 1;
      // (name, this.page).then((data) => {
      //   console.log(data.data);
      //   if (data.data == null) {
      //     Message.warning("查询内容不存在！");
      //   } else {
      //     this.tableData = data.data.list;
      //     this.total = data.data.total;
      //     this.currentPage = data.data.pageNum;
      //   }
      // });
    },
    //导出
    handleExport() {
      let res = this.perList.filter((val) => val == 7);
      console.log(res)
      if(!res.length){
        Message.error('您没有这个权限');
        
      }else{
      downloadFile(BASE_URL + `/user/exportProcuratorateUser`);
      }
    },
    //表单
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //分页器
    handleCurrentChange(val) {
      this.page = val;
      // if (this.selectTxt !== "") {
      //   this.departSelect();
      //   return;
      // }
      this._getAllUserInfo();
    },
    //判断权限
    poster() {
      if (this.checkHear == true) {
        this.form.authorityHering = "1";
      } else {
        this.form.authorityHering = "0";
      }
      if (this.checkUser == true) {
        this.form.authorityJian = "1";
      } else {
        this.form.authorityJian = "0";
      }
      if (this.checkExpert == true) {
        this.form.authorityZhuan = "1";
      } else {
        this.form.authorityZhuan = "0";
      }
    },
    closeDialog() {
      this.dialogFormVisible = false;
    },
  },
  computed: {},
};
</script>
<style lang='less' >
.policemen_warp {
  box-sizing: border-box;
  position: relative;
  width: auto;
  height: 92%;
  background: #fff;
  margin-top: 16px;
  padding: 20px 13px 0 13px;
  .main_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .el-dialog {
    margin: 300px auto !important;
    width: 30% !important;
    min-width: 570px;
  }

  .el-dialog__header {
    border-bottom: 1px solid #e9e6e6;
  }
  .el-dialog__footer {
    border-top: 1px solid #e9e6e6;
  }
  .dialog_title {
    > img {
      width: 18px;
      height: 18px;
      margin-right: 5px;
    }
    > span {
      font-size: 13px;
      font-weight: 400;
      color: #2d8cf0;
    }
  }
  .form_label {
    > span {
      color: red;
      margin-right: 5px;
    }
  }
  .el-form-item__label {
    text-align: left;
    .form_label {
      font-size: 13px;
    }
  }
  .el-form-item {
    margin-bottom: 10px;
  }
  .el-form-item .el-input__inner {
    width: 275px;
    height: 23px;
  }
  .el-dialog__body {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .style_button {
    width: 100px;
    height: 40px;
    background: #044bfe;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 300;
    color: #ffffff;
  }
  .style_button_del {
    background: #ff0012;
    margin-left: 13px;
  }
  .el-table td,
  .el-table th {
    text-align: center;
    padding: 8px 0;
  }
  .el-table {
    margin-top: 15px;
    .el-button {
      width: 80px;
      height: 36px;
      font-size: 14px;
      color: #ffffff;
    }
    .editColor {
      background: #3a8fea;
    }
  }
  .el-button--danger {
    background: #ff5200 !important;
  }
  .el-pagination {
    // position: absolute;
    // bottom: 34px;
    // left: 50%;
    // transform: translateX(-50%);
    text-align: center;
    margin-top: 15px;
  }
  .el-pager {
    > li {
      border: 1px solid #d2dae2;
      border-radius: 2px 2px 2px 2px;
      width: 28px !important;
      height: 28px !important;
      font-size: 12px;
      margin-right: 5px;
      min-width: 28px;
    }
    > li:last-child {
      margin-right: 0;
    }
    > li.active {
      box-sizing: border-box;
      background: #3a8fea;
      color: #fff;
      width: 28px !important;
      height: 28px !important;
    }
    li.active + li {
      border-left: 1px solid #d2dae2;
    }
  }
}
.el-pagination .btn-prev,
.el-pagination .btn-next {
  border: 1px solid #d2dae2;
  border-radius: 2px 2px 2px 2px;
  width: 28px !important;
  height: 28px !important;
  font-size: 12px;
  margin: 0 5px;
  min-width: 28px;
}
.style_button_Sel {
  margin-left: 10px;
}
.el-table_1_column_5 .cell {
  display: flex;
  justify-content: center;
  p {
    margin-right: 10px;
  }
}
</style>